<template>
   <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
      <el-form-item label="活动名称" prop="eventName">
         <el-input class="width-370" v-model="ruleForm.eventName" placeholder="活动名称"></el-input>
      </el-form-item>
      <slot name="activityCycle"></slot>
      <el-form-item label="转化跟踪">
         <el-switch v-model="ruleForm.isConvert" active-value="YES" inactive-value="NO"  active-color="#1489e2" inactive-color="#c5ced8"></el-switch>
         <el-popover placement="top-start" title="转化跟踪" width="200" trigger="hover" content="在活动中开启转化跟踪后，可以设置一个事件作为转化目标，系统会自动
                  生成一个转化漏斗并计算转化率。在活动中，一般设置落地页后续的某个业务事件作为目标，比如：加入购物车、提交订单、报名参与等。">
            <i class="el-icon-question" slot="reference"></i>
         </el-popover>
      </el-form-item>
      <el-form-item label="转化目标" prop="targetId" v-show="ruleForm.isConvert === 'YES'">
         <el-select class="width-370" v-model="ruleForm.targetId" placeholder="请选择">
            <el-option
                v-for="item in targetOpt"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
         </el-select>
      </el-form-item>
      <el-form-item label="转化周期" prop="days" v-show="ruleForm.isConvert === 'YES'">
         <el-input class="width-370 m-right-5" v-model.number="ruleForm.days" placeholder="转化周期"></el-input>天内
      </el-form-item>
      <el-form-item label="触发群组" prop="groupList" class="trigger-group">
         <div class="select-box" v-for="(row, index) in ruleForm.groupList" :key="index" >
            <el-select class="width-370" v-model="row.groupId" placeholder="请选择" :disabled="ruleForm.checked" clearable>
               <el-option
                   v-for="item in groupOpt" :key="item.value" :label="item.label" :value="item.value" :disabled="item.memberCount < 2">
               </el-option>
            </el-select>
            <i class="el-icon-circle-close" @click="removeGroup(index)"></i>
         </div>
         <el-button class="add-btn" size="mini" @click="insertGroup">添加</el-button>
         <p v-if="msgType === 'MASS'" class="checked"><el-checkbox v-model="ruleForm.checked">全部群发</el-checkbox></p>
      </el-form-item>
      <slot name="pushRule"></slot>
      <el-form-item>
         <el-button class="bg-gradient" @click="submit">下一步</el-button>
      </el-form-item>
   </el-form>
</template>
<script>
import { urlObj  } from '@/api/interface'
import { label } from "@/api/interface/data";
import { mapState } from "vuex";
export default {
   props:{
      activityCycle:{
         type:Array,
         default () {
            return [];
         }
      }
   },
   watch:{activityCycle(){ this.ruleForm.activityCycle = this.activityCycle }},
   data() {
      let validTarget = (rule, value, callback) => {
         if (this.ruleForm.isConvert === 'YES' && !value) callback(new Error('转化目标不能为空！'))
         else callback()
      }
      let validDays = (rule, value, callback) => {
         if (this.ruleForm.isConvert === 'YES' && !value) callback(new Error('转化周期不能为空！'))
         else if (!Number.isInteger(value)) callback(new Error('周期必须是数字！'))
         else callback()
      }
      let validGroup = (rule, value, callback) => {
         if (this.msgType === 'CUSTOMER' && value.find(item => !item.groupId)) callback(new Error('请选择触发群组！'))
         else callback()
      }
      let validActive =  (rule, value, callback) => {
         if (!value[0] || !value[1]) callback(new Error('活动周期不能为空！'))
         else callback()
      }
      return {
         targetOpt: [],
         groupOpt: [],
         ruleForm: {
            eventName: '',
            isConvert: 'NO',
            targetId: '',
            days: 0,
            groupList: [{}],
            id:'',
            checked: false,
            state:'IN_PROGRESS',
            activityCycle:[]
         },
         rules: {
            eventName: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
            targetId: [{ required: true, validator: validTarget, trigger: 'change' }],
            days: [{ required: true, validator: validDays, trigger: 'change' }],
            groupList: [{ required: true, validator: validGroup, trigger: 'change' }],
            activityCycle :[{ required: true, validator: validActive, trigger: 'change' }],
         },
         msgType: ''
      }
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   mounted() {
      this.getTargetList()
      this.getGroupList()
      this.msgType = this.$route.query.msgType
      if(this.$route.query.action === 'add') delete this.ruleForm.id
      else this.getEditInfo()
   },
   methods: {
      // 获取编辑信息
      getEditInfo() {
         const activityObj = JSON.parse(sessionStorage.getItem('activityObj') || '{}')
         for (const k in this.ruleForm) k in activityObj && (this.ruleForm[k] = activityObj[k])
         this.ruleForm.groupList = activityObj.eventGroupRefList.map(item => ({ groupId: item.groupId }))
         if(this.$route.query.action === 'copy') this.ruleForm.eventName = this.ruleForm.eventName + '(副本)'
      },
      // 获取转化目标列表
      getTargetList(category) {
         const url = urlObj.metadataList
         const param = { category }
         this.$axios.post(url, param).then(res => {
            res.success && (this.targetOpt = res.records.map(item => ({ label: item.name, value: item.id })))
         })
      },
      // 获取群组列表
      getGroupList() {
         const url = label.tagGroupList
         const param = { companyId: this.hotelInfo.storeId }
         this.$axios.post(url, param).then(res => {
            if (!res.success) return
            this.groupOpt = res.records.map(item => ({ label: item.groupName, value: item.id, memberCount: item.memberCount }))
         })
      },
      // 新增群组
      insertGroup() {
         this.ruleForm.groupList.push({})
      },
      // 移除群组
      removeGroup(index) {
         this.ruleForm.groupList.length > 1 && this.ruleForm.groupList.splice(index, 1)
      },
      // 下一步
       submit() {
         this.$refs.ruleForm.validate(valid => {
            if (!valid) return
            this.$emit('nextStep', this.ruleForm)
         })
      },
   }
}
</script>
<style lang="scss" scoped>
.el-form{
   .el-icon-question{
      display: inline-block; color: #777777; padding: 1px; border: solid 1px #eeeeee;
      margin-left: 4px; border-radius: 4px
   }
   .trigger-group {
      .add-btn { margin-left: 80px }
      .select-box{ margin: 8px 0 0 77px }
      .select-box:nth-child(1) { margin: 0 }
      .el-icon-circle-close{ font-size: 20px; color: #999999; margin-left: 4px }
      .checked{ margin-left: 80px }
   }
   ::v-deep .el-form-item__error{ left: 76px }
}
</style>
